<template>
    <v-card flat>
        <v-card-text class="pl-0 pr-0 pb-0">
            <tile-info v-if="!modifyMode" @goModify="goModifyMode"></tile-info>
            <tile-modify v-else @goInfo="goInfoMode"></tile-modify>
            <tile-list v-if="!modifyMode"></tile-list>
            <tile-hist-list v-if="!modifyMode && adminHistoryList && adminHistoryList.length > 0"></tile-hist-list>

            <v-row v-if="!modifyMode && companyLvType == 'PRO' && mainAdminYn == 'Y'" class="mt-2 mr-1">
                <v-col cols="12" class="text-right">
                    <v-btn large rounded outlined class="red--text text-h6" @click="openCheckPasswordPopup">
                        서비스 해지
                        <v-icon class="ml-3">mdi-exit-to-app</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-dialog v-model="modal" persistent max-width="600" @keydown.esc="closePopup()">
                <v-flex v-if="dialogMode === 'checkPassword'">
                    <popup-check-password v-on:submit="doCheckPassword" v-on:cancel="closePopup"></popup-check-password>
                </v-flex>
            </v-dialog>
        </v-card-text>
    </v-card>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
    name: 'svc_user',
    components: {
        'tileInfo': () => import('@/components/admin/tile_info'),
        'tileModify': () => import('@/components/admin/tile_modify'),
        'tileList': () => import('@/components/admin/tile_list'),
        'tileHistList': () => import('@/components/admin/tile_hist_list'),
        'popupCheckPassword': () => import('@/components/admin/popup_check_password.vue'),
    },
    data: () => ({
        modifyMode: false,
        modal: false,
        dialogMode: 'none'
    }),
    computed: {
        ...mapGetters({
            companyLvType: 'login/getCompanyLvType',
            mainAdminYn: 'login/getMainAdminYn',
            adminHistoryList: 'admin/getAdminHistoryList',
        }),
    },
    created() {
        this.$store.commit('layoutFlag', true)
        this.$store.commit('menuId', 'admin')
    },
    methods: {
        goModifyMode() {
            this.modifyMode = true
        },
        goInfoMode() {
            this.modifyMode = false
        },
        goSecede() {
            this.$router.push('/admin/secede')
        },
        openCheckPasswordPopup() {
            this.dialogMode = 'checkPassword'
            this.modal = true
        },
        closePopup() {
            this.modal = false
            this.dialogMode = 'none'
        },
        doCheckPassword() {
            this.closePopup()
            this.goSecede()
        }
    }
}
</script>

